<template>
  <div class="modal fade" id="available-offer" tabindex="-1" role="dialog"  aria-modal="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <div class="available-offer-main">
          <h2 class="text-center mb-0">Available Offers</h2>
          <div class="form-group enter-code d-flex">
            <input type="text" name="Enter Code" placeholder="Enter Code" class="w-100 border-0" v-model="offercode">
            <button :style="textcolor" class="btn apply noHover mb-2 mb-sm-0" @click="ApplidOffr()">Apply</button>
          </div>
          <div class="discount-offers" v-for="(offerdetail, index) in getofferdetails.data" v-bind:key="index">
            <div class="media">
              <img :src="offerdetail.offer_image" class="mr-3" alt="Discount">
                <div class="media-body">
                    <h5 class="mt-0 mb-1">{{offerdetail.title}}</h5>
                    <span>{{offerdetail.validity}}</span>
                </div>
            </div>
            <p>{{offerdetail.minimum_value}}</p>
            <div class="offer-buttons d-flex flex-wrap justify-content-between align-items-center">
              <div class="d-flex align-items-center">
              <button 
                :style="colorObj"
                class=" btn lokaly-btn mr-3 rounded-0 hoverEffect"
                :class="['long', (offerdetail.discount_code.length > 8 && offerdetail.discount_code.length<=12 ? 'code' : ''), (offerdetail.discount_code.length>12 &&  offerdetail.discount_code.length<=16?'code-2' : ''),(offerdetail.discount_code.length>16 &&  offerdetail.discount_code.length<=20?'code-3' : '')]"
                v-clipboard:copy="offerdetail.discount_code"
                v-clipboard:success="clipboardSuccessHandler"
                v-if="offerdetail.discount_code"
              >{{ offerdetail.discount_code}}</button>
              <a href="javascript:void(0)" :style="textcolor" @click="viewOfferDetail(offerdetail)" class="d-inline-block view-details mr-3">View Details</a>
            </div>
            <button class="btn apply noHover mb-2 mb-sm-0" :style="textcolor" @click="applyOffrcode(offerdetail.discount_code, offerdetail.title)"> Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../main";
import helper from "../../store/helper"
export default {
  name: 'ApplyOffer',
  props:{
    getofferdetails:null
  },
  mixins:[helper],
  data() {
    return {
      offercode:'',
      offertitle:''
    }
  },
  mounted(){
    this.originalColor();
  },
  methods: {
    applyOffrcode(offerval, offertitle){
      this.offercode = offerval;
      this.offertitle = offertitle;
      window.$('#available-offer').modal('hide');
      this.$emit('update', this.offercode, this.offertitle);
    },
    ApplidOffr(){
      if(this.offercode != ''){
        window.$('#available-offer').modal('hide');
        this.$emit('update', this.offercode, '');
      }
    },
    viewOfferDetail(offrlist) {
      bus.$emit("offerdetail", offrlist);
      window.$("#Detailpopup").modal("show");
    },
    clipboardSuccessHandler () {
      this.$toast.success("Code copied to clipboard");
    },
  },
}
</script>